import styled from 'styled-components'
import Logo from '../../../assets/images/MSD-logo-white.svg'

export const LogoMSD = styled(Logo)`
  width: 8rem;
  height: auto;
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`
