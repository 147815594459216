import H1 from '../H1'
import React from 'react'
import Footer from '../Footer'

const pageCertificate = {
  jobCode: 'GB-CIN-00404',
  dateOfPrep: 'August 2023',
}

const SiteUnavailable = () => (
  <>
    <main className="content p-4">
      <H1>Shinglesaware.co.uk is no longer available</H1>
      <p>
        For more information on Shingles, please contact your local healthcare
        provider.
      </p>
    </main>
    <div className="position-absolute bottom-0 start-0">
      <Footer certificate={pageCertificate} />
    </div>
  </>
)

export default SiteUnavailable
