import React from 'react'
import Copyright from './Copyright'

import styled from 'styled-components'
import {breakpoints, mediaUp} from '../../helpers/media-queries'

const StyledFooter = styled.footer`
  padding: 2.5rem 0;

  ${mediaUp(breakpoints.lg)} {
    padding: 5rem 0;
  }
`

export interface Certificate {
  jobCode: string
  dateOfPrep: string
}
interface Props {
  certificate: Certificate
}

const Footer: React.FC<Props> = ({certificate}) => (
  <StyledFooter>
    <Copyright {...certificate} />
  </StyledFooter>
)

export default Footer
