import {Certificate} from '../'
import React from 'react'
import {List, LogoMSD} from './styles'

const Copyright: React.FC<Certificate> = ({jobCode, dateOfPrep}) => (
  <>
    <List className="px-4 py-1">
      <li>
        <span className="job-bag-no">{jobCode}</span>
        {' | Date of Preparation: '}
        <span className="date-of-prep">{dateOfPrep}</span>
      </li>
      <LogoMSD />
      <li>
        Merck Sharp & Dohme (UK) Limited Registered Office: 120 Moorgate,
        London, EC2M 6UR, United Kingdom. Registered in England No. 233687
        Copyright © 2022 Merck & Co., Inc., Rahway, NJ, USA and its
        affiliates.All rights reserved.
      </li>
    </List>
  </>
)

export default Copyright
